/* Services related css */
.border-gradient-cards {
  border: 1px solid;
  border-image-source: radial-gradient(
    142.36% 126.71% at 74.86%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.li-color li::marker {
  color: #ff2e29;
}

div iframe {
  width: 100%;
  height: 100%;
}
.shadow-services {
  background-color: #c7dddb2e;
  border-radius: 40%;
  display: block;
  filter: blur(60px);
  height: 55rem;
  position: absolute;
  left: -1rem;
  top: -3rem;
  width: 42rem;
  z-index: -1;
}
.shadow-serv {
  height: 59rem !important;
  left: 0rem !important;
  width: 42rem !important;
}
.rs-shadow {
  height: 35rem !important;
  left: 0rem !important;
  width: 28rem !important;
}
@media (min-width: 1920px) and (max-width: 1920px) {
  .shadow-services {
    left: -1rem !important;
    width: 50rem !important;
    height: 70rem !important;
  }
  .shadow-serv {
    left: 0rem !important;
  }
}
@media (min-width: 1152px) and (max-width: 1152px) {
  .shadow-services {
    width: 35rem !important;
    height: 45rem !important;
  }
}

@media (min-width: 1706.67px) and (max-width: 1706.67px) {
  .shadow-services {
    left: -1rem !important;
    width: 45rem !important;
    height: 62rem !important;
  }
}
@media (min-width: 1396.36px) and (max-width: 1396.36px) {
  .shadow-services {
    left: -2rem !important;
    width: 38rem !important;
    height: 50rem !important;
  }
}
@media (min-width: 1228.8px) and (max-width: 1228.8px) {
  .shadow-services {
    left: -2rem !important;
    width: 34rem !important;
    height: 45rem !important;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .shadow-services {
    left: -2rem !important;
    width: 28rem;
    height: 38rem;
  }
}
@media (min-width: 2133.33px) and (max-width: 2133.33px) {
  .shadow-services {
    left: -2rem !important;
    width: 56rem;
    height: 75rem;
  }
}
@media (min-width: 2400px) and (max-width: 2400px) {
  .shadow-services {
    left: -2rem !important;
    width: 62rem;
    height: 85rem;
  }
}
@media (min-width: 1745.45px) and (max-width: 1745.45px) {
  .shadow-services {
    left: -2rem !important;
    width: 47rem;
    height: 65rem;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .shadow-services {
    left: -2rem !important;
    width: 35rem;
    height: 47rem;
  }
}
@media (min-width: 1163.64px) and (max-width: 1163.64px) {
  .shadow-services {
    left: -2rem !important;
    width: 32rem;
    height: 42rem;
  }
}
@media (min-width: 1422.22px) and (max-width: 1422.22px) {
  .shadow-services {
    left: -2rem !important;
    width: 38rem;
    height: 50rem;
  }
}
@media (min-width: 1098.29px) and (max-width: 1098.29px) {
  .shadow-services {
    left: -2rem !important;
    width: 30rem;
    height: 40rem;
  }
}
#player video {
  width: 100% !important;
  left: 0 !important;
}
@media (min-width: 961px) and (max-width: 1023px) {
  .head-proud {
    font-size: 75px;
    line-height: 5rem;
  }
  .mobile-top {
    top: -35%;
  }
}

@media screen and (min-width: 1024px) {
  .corner-images::before,
  .corner-images::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .corner-images::before {
    top: 0rem;
    right: -1px;
    background-image: url('/public/images/Rectangle_top_left.png'),
      url('/public/images/Rectangle_top_right.png');
    background-position:
      0 0,
      100% 0;
  }

  .corner-images::after {
    bottom: 0rem;
    left: 1px;
    background-image: url('/public/images/Rectangle_bottom_left.png'),
      url('/public/images/Rectangle_bottom_right.png');
    background-position:
      0 100%,
      100% 100%;
  }
}

.corner-images img {
  position: relative;
  z-index: 1;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
