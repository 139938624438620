/* Portfolio Page Css here  */

.portfolio-page .portfolio-link a:hover {
  color: #ff2e29;
}
.portfolio-link #active {
  color: #ff2e29;
  text-decoration: underline;
}

.portfolio-page .shadow {
  background-color: #ffffff57;
  border-radius: 50%;
  display: block;
  -webkit-filter: blur(40px) !important;
  filter: blur(40px);
  height: 80vh;
  opacity: 0.3;
  position: absolute;
  left: -15rem;
  top: 5%;
  -webkit-transform: rotate(195deg);
  transform: rotate(195deg);
  width: 100vw;
  z-index: -1;
}
.portfolio-header {
  text-transform: uppercase;
  padding: 0.25rem;
}
.portfolio-page .web-menu {
  margin: 7vw;
}

@media screen and (max-width: 991px) {
  .portfolio-page .border-left {
    display: none;
  }
  .portfolio-page .border-right {
    display: none;
  }
  .portfolio-header .last-line {
    padding-left: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-header .last-line {
    padding-left: 4rem;
  }
}

.portfolio-page .portfolio-link a {
  font-size: 1.5rem;
}
.max-screen {
  position: absolute;
  top: 7.5%;
  right: 4.5%;
  height: 0.8rem;
}
.portfolio-middle-border {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0) -2.11%,
    #fff 38.7%,
    hsla(0, 0%, 100%, 0)
  );
  content: '';
  height: 1px;
  left: 0;
  opacity: 0.2;

  right: 0;
  top: 0;
}

.portfolio-page .border-left {
  width: auto;
  position: absolute;
  bottom: 0%;
  left: 5.66%;
  height: 100%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}

.portfolio-page .border-right {
  width: auto;
  position: absolute;
  bottom: 15%;
  right: 5.66%;

  height: 60%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.mobile-portfolio-link {
  margin-left: 3rem;
}
.portfolio-page .sub-heading {
  display: none;
}
.portfolio-page .mobile-menu {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-art {
    align-items: center;
    margin: 3rem;
  }
  .portfolio-page .mobile-menu {
    display: block;
  }
  .portfolio-page .sub-heading {
    color: #ff2e29;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 1rem;
    display: block;
  }

  .portfolio-page #active {
    color: #ff2e29;
  }
  .mobile-art-image {
    width: 25rem;
  }
  .portfolio-link div:first-child {
    width: 100%;
  }
}

@media screen and (max-width: 468px) {
  .portfolio-page .other-link {
    grid-template-columns: 1fr 1fr;
  }
  .mobile-art-image {
    width: 20rem;
  }
}

@media screen and (max-width: 300px) {
  .portfolio-page .other-link {
    grid-template-columns: 1fr;
  }
  .mobile-art-image {
    width: 15rem;
  }
}
.custombtn button {
  font-family: Helvetica-Neue-Regular;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  letter-spacing: 1px;
  padding: 0.8rem 3rem 0.9rem 2rem;
  border-radius: 24px 10px 10px 24px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.custombtn .arrowcircle {
  background-color: #0c0c0c;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.1rem;
  height: 3.55rem;
  border-radius: 22px 22px 22px 22px;
  border: 1px solid #fff;
  margin-left: -1.5rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#0c0c0c, #0c0c0c 50%, red 50%, red);
  background-size: 100% 200%;
}
.custombtn .animateText {
  position: relative;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  display: block;
  float: left;
  height: 1.1rem;
  white-space: nowrap;
}
.custombtn .custombtn .animateText2 {
  display: block;
  position: relative;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transform: translate(0, 100%);
  -webkit-transform: translate(0, 100%);
  height: 1.1rem;
}
.custombtn {
  display: inline-flex;
  align-items: center;
  margin: 3rem;
  cursor: pointer;
}

.custombtn span {
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

/* modal popup css  */

.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  transition: opacity 100ms ease-in;
}

.close {
  font-family: Arial, Helvetica, sans-serif;
  background: #ff2e29;
  color: #fff;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 40px;
  height: 40px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 1px 1px 3px #000;
  padding-top: 5px;
  border: none;
}

.close:hover {
  background: #ff2e29;
  cursor: pointer;
}

.nda_content p {
  font-size: 16px !important;
}
.portfolio-modal-label {
  display: block;
  margin-bottom: 0.5rem;
}

.portfolio-modal-input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.portfolio-btn {
  background-color: #ff2e29;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.portfolio-btn:disabled {
  background-color: #ccc;
}

.portfolio-btn:not(:disabled):hover {
  background-color: #ff2e29;
}

/* image modal Css */
.image-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition:
    visibility 0s linear 0.25s,
    opacity 0.25s 0s,
    transform 0.25s;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 100%;
  border-radius: 0.5rem;
}
.close-button {
  float: right;
}
.close-button:hover {
  background-color: darkgray;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition:
    visibility 0s linear 0s,
    opacity 0.25s 0s,
    transform 0.25s;
}
/* width */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #ff2e29;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #ff2e29;
}
.bg-image {
  background-image: url(../../../public/images/portfolio-shadow.png);
  background-position: center;
}

.blur-background {
  filter: blur(5px);
}
