/* Project Inner Css here  */
.detail-page-description p {
  font-weight: 300;
  margin-bottom: 1rem;
  font-size: 14px;
}
.video-section iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .detail-page-description p {
    font-size: 16px;
  }
}
.custom-right-border {
  width: auto;
  height: 35%;
  position: absolute;
  bottom: -5rem;

  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.custom-top-border {
  width: 100%;
  border-top: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
@media screen and (max-width: 768px) {
  .detail-page-description p {
    line-height: 20px;
  }
}

u a {
  color: #ff2e29;
}
@media only screen and (min-device-width: 513px) and (max-device-width: 767px) {
  .pad-55 {
    padding-top: 55% !important;
  }
}

.detail-page-description a:hover {
  color: #ff2e29;
}

body:has(.hover-prev:hover) .prev-button {
  display: block;
}
body:has(.hover-next:hover) .next-button {
  display: block;
}

@keyframes zoomInAnimationProjectInner {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

.zoom-animate-projectInner {
  animation: zoomInAnimationProjectInner 1s ease-in-out;
}
