/* project page css */

.project-heading {
  text-transform: uppercase;
  padding: 0.25rem;
}
.project-subheading {
  top: 12%;
  left: 60%;
}

.project-heading span {
  font-weight: 800;
  display: block;
}

/* Project List Css here  */

.project-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 991px) {
  .project-card-container {
    grid-template-columns: 1fr;
  }
}
.project-pills-container {
  width: 100%;
  max-width: 1340px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .project-pills-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .project-pills-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .project-pills-container {
    max-width: 1012px;
  }
  .projectlist-container .project-pills-container {
    padding-inline: 5rem;
  }
}

@media (min-width: 1200px) {
  .project-pills-container {
    max-width: 1340px;
  }
}
@media (min-width: 1600px) {
  .project-pills-container {
    max-width: 1520px;
  }
}
@media (min-width: 1920px) {
  .project-pills-container {
    max-width: 1820px;
  }
}
.order-border {
  border: 1px solid;

  border-image-source: linear-gradient(
    148.72deg,
    rgba(255, 255, 255, 0.46) -238.27%,
    rgba(255, 255, 255, 0) 199.51%
  );
  border-image-slice: 1;
}
.alpha-border {
  border-bottom: 1px solid;

  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 62.77%,
    rgba(255, 255, 255, 0) 110.48%
  );
  border-image-slice: 1;
}

.corner-images-project {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .corner-images-project::before,
  .corner-images-project::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .corner-images-project::before {
    top: 0rem;
    background-image:
      url('/public/images/Rectangle_top_left.png'),
      url('/public/images/Rectangle_top_right.png');
    background-position:
      0 0,
      100% 0;
  }

  .corner-images-project::after {
    bottom: 0rem;
    background-image:
      url('/public/images/Rectangle_bottom_left.png'),
      url('/public/images/Rectangle_bottom_right.png');
    background-position:
      0 100%,
      100% 100%;
  }
}

.corner-images-project img {
  position: relative;
  z-index: 1;
}
.custom-border-project-list {
  border-top: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.project-page-border {
  border: 0.1px solid;

  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) -2.11%,
    #ffffff 8.7%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}

/* Feature project  css */

.Feature-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Feature-project .left-detail .left-detail-heading {
  width: 40rem;
}
.Feature-project .top-border {
  position: absolute;
  border-image: linear-gradient(90deg, #262626 20%, #0c0c0c) 1;
  border-top: 1px solid;
  top: 5%;
  left: 5%;
  width: 55%;
}
.Feature-project .bottom-border {
  position: absolute;
  border-image: linear-gradient(90deg, #262626 20%, #0c0c0c) 1;
  border-top: 1px solid;
  bottom: -15%;
  left: 5%;
  width: 55%;
}
.Feature-project .left-detail {
  margin-left: auto;
}

.Feature-project .left-detail p {
  font-size: 1rem;
  font-weight: 500;
}
.Feature-project .left-detail h1 {
  font-size: 3.3rem;
  font-weight: 800;
}
.Feature-project .left-detail .view-project {
  color: #ff2e29;
  font-size: 1rem;
  right: 5%;

  bottom: 10%;
}
.Feature-project .right-detail .shadow-image img {
  opacity: 35%;
  background-size: cover;
  filter: grayscale(0.5);
}

.Feature-project .shadow-image img {
  max-width: none;
}

.Feature-project .view-main-project {
  height: 7rem;
  width: 7rem;
  background-color: #ff2e29;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  top: 46%;
  left: 9%;
}

@media screen and (min-width: 1700px) {
  .Feature-project .left-detail h1 {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1699px) {
  .Feature-project .left-detail .left-detail-heading {
    width: 30rem;
  }
  .Feature-project .left-detail h1 {
    font-size: 3rem;
  }
  .Feature-project .shadow-image img {
    height: 20rem;
    width: 18rem;
  }
  .Feature-project .right-detail .main-image img {
    height: 22rem;
  }
  .Feature-project .feature-arrowright {
    height: 4rem;
    width: 4rem;
  }
  .Feature-project .feature-arrowleft {
    height: 4rem;
    width: 4rem;
  }
}

@media screen and (max-width: 1200px) {
  .Feature-project .left-detail .left-detail-heading {
    width: 20rem;
  }

  .Feature-project .right-detail .image-part {
    width: 30rem;
  }
  .Feature-project .shadow-image img {
    height: 18rem;
    width: 12rem;
  }
  .Feature-project .right-detail .main-image img {
    height: 20rem;
  }
  .Feature-project .feature-arrowright {
    height: 4rem;
    width: 4rem;
  }
  .Feature-project .feature-arrowleft {
    height: 4rem;
    width: 4rem;
  }
  .Feature-project .left-detail h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .Feature-project .top-border {
    display: none;
  }

  .Feature-project .left-detail .left-detail-heading {
    width: 100vw;
  }
  .Feature-project .bottom-border {
    display: none;
  }

  .Feature-project .view-main-project {
    background-color: #ff2e29;
    border-radius: 28px;
    height: 3.5rem;
    width: 10rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    top: 80%;
    left: 15%;
    font-size: 0.7rem;
  }
  .Feature-project {
    grid-template-columns: 1fr;
    margin-inline: auto;
  }
  .Feature-project .left-detail .left-detail-heading {
    margin-left: auto;
  }
  .Feature-project .left-detail .left-detail-heading h1 {
    font-size: 1.8rem;
  }
  .Feature-project .image-part {
    margin-left: auto;
  }
  .Feature-project .left-detail {
    order: 2;
    margin-right: auto;
    max-width: 960px;
    padding-inline: 5rem;
  }
  .Feature-project .left-detail .view-project {
    position: relative;
    margin-left: 1.2rem;
    margin-top: 2rem;
  }
  .Feature-project .shadow-image {
    display: none;
  }
  .Feature-project .right-detail {
    order: 1;
  }
  .feature-arrow {
    right: 10%;
    left: 0%;
  }
}
@media screen and (max-width: 576px) {
  .Feature-project .left-detail {
    order: 2;

    max-width: 540px;
    padding-inline: 1rem;
  }
  .Feature-project .left-detail {
    margin-left: 1rem;
  }
}
.Feature-project {
  height: 30rem;
}
.hover-color:hover .arrow-color {
  fill: white;
}
.right-border-project-listing {
  width: auto;
  height: 30%;
  position: absolute;
  bottom: -29rem;
  right: 4%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.left-border-project-listing {
  width: auto;
  height: 30%;
  position: absolute;
  bottom: -29rem;
  left: 4%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.custom-top-border {
  width: 100%;
  border-top: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.project-border {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0) -2.11%,
    #fff 38.7%,
    hsla(0, 0%, 100%, 0)
  );
  content: '';
  height: 1px;
  opacity: 0.2;
}

/* animation adding to featured projects */
@keyframes zoomInAnimation {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.zoom-animate {
  animation: zoomInAnimation 0.5s ease-in-out;
}
.image-container img {
  transition: transform 0.3s ease-in-out;
}
.projects-slant:hover .projects-slant-child {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
}
