/* Tell us Your Story related css */

.verticalLine-tell-left {
  height: 25rem;
  bottom: 0%;
  position: absolute;
  left: 12%;
  border-left: 1px solid #ffffff40;
  background: transparent;
  margin-left: 10px;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 30%) 1;
}
.verticalLine-tell-right {
  position: absolute;
  width: auto;
  right: 12%;
  top: -37% !important;
  margin-right: 10px;
  height: 25rem;
  border-right: 1px solid #ffffff40;
  background: transparent;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tell-top-line {
    color: white;
    padding-top: 1rem;
    border-top: 1px solid #ffffff40;
  }
  .whiteshadow {
    display: none !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tell-top-line {
    color: white;
    padding-top: 1rem;
    border-top: 1px solid #ffffff40;
  }
  .whiteshadow {
    display: none !important;
  }
}

.whiteshadow {
  background-color: #ffffffbd;
  border-radius: 50%;
  display: block;
  filter: blur(80px);
  height: 100%;
  opacity: 0.15;
  position: absolute;
  right: -15%;
  top: 14%;
  transform: rotate(270deg);
  width: 50%;
  z-index: -1;
}
.test-shadow {
  background-color: #fff;
  border-radius: 50% 50% 0 0;
  display: block;
  filter: blur(50px);
  position: absolute;
  right: 25%;
  top: 60%;
  width: 50%;
  height: 50%;
  z-index: -1;
}
