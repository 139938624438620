/* Footer related css */
.footer .footerlinks {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 4rem;
}

.footer .footerlinks .leftpart {
  width: 50%;
}

.footer label {
  opacity: 0.6;
  mix-blend-mode: difference;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  display: block;
}

.footer .footerlinks .links h6 {
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: 100;
}

.footer .footerlinks .links h6 a {
  color: #fff;
  text-decoration: unset;
  display: inline-block;
  /* margin-top: 1rem; */
  transition: all 0.2s ease-in-out;
}

.footer .footerlinks .mobilelinks {
  margin-right: 4rem;
}

.footer .footerlinks .mobilelinks {
  display: none;
}

.footer .footerlinks .mailto label {
  margin-top: 5rem;
}

.footer .footerlinks .mailto h4 {
  margin: 0;
  font-weight: normal;
}

.footer .footerlinks .rightpart {
  width: 50%;
}

.footer .rightpart .footernav {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.footer .rightpart h6 {
  margin: 0;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 100;
}

.footer .rightpart a {
  text-decoration: unset;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-family: 'Montserrat';
  font-weight: 800;
}

.footer .footerbottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  width: 95%;
  margin: 0 auto;
  border-top: 1px solid #3c3c3c;
}

.footer .footerbottom .leftpart {
  display: flex;
  align-items: center;
  column-gap: 3rem;
}

.footer .footerbottom p {
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.footer .footerbottom p {
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.footer .footerbottom .leftpart a {
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.footer .footerbottom .rightpart {
  margin-left: auto;
}

.footer .footerbottom .rightpart p {
  color: #999898;
}

footer {
  color: white;
  padding-top: 4rem;
  border-top: 1px solid #ffffff40;
}

.footer-top {
  text-align: center;
  position: relative;
}

@media screen and (max-width: 991px) {
  .footer .footerlinks {
    flex-direction: column-reverse;
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .footer label {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .footer .footerlinks .leftpart {
    width: 100%;
    margin-top: 3rem;
  }

  .footer .footerlinks .rightpart {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .footer .footerlinks .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;
  }

  .footer .footerlinks .mobilelinks {
    display: none;
  }
}

@media screen and (max-width: 376px) {
  .footer .footerlinks .links {
    text-align: -webkit-center;
  }
}

.footer .footerlinks .mobilelinks {
  margin-right: 4rem;
}

@media screen and (max-width: 600px) {
  .footer .footerlinks .links {
    /* display: none; */
    text-align: -webkit-center;
  }

  .footer .footerlinks .mobilelinks {
    display: block;
  }

  .topLine {
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .footer .footerlinks .links h6 {
    text-align: center;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 991px) {
  .footer .footerlinks .links h6 a:hover {
    color: var(--primary-color);
    transform: translateY(-0.1rem);
  }
}

@media screen and (max-width: 991px) {
  .footer .footerlinks .mailto h4 {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .footer .rightpart .footernav {
    align-items: center;
    row-gap: 1rem;
  }

  @media screen and (max-width: 991px) {
    .footer .rightpart h6 {
      font-size: 3vw;
    }
  }
}

@media screen and (max-width: 991px) {
  .footer .footerbottom {
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .footer .footerbottom .leftpart {
    flex-direction: column;
    row-gap: 1rem;
    position: unset;
  }

  .footer .footerbottom .rightpart {
    margin-left: unset;
  }
}

@media screen and (max-width: 1200px) {
  .footer .footerbottom p {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 991px) {
  .footer .footerbottom p {
    width: 100%;
    text-align: center;
  }
}
.email-font a {
  font-family: 'Montserrat';
}
