/* Sub Portfolio Css here  */

.subportfolio-page .shadow {
  background-color: #ffffff57;
  border-radius: 50%;
  display: block;
  -webkit-filter: blur(40px);
  filter: blur(80px);
  height: 80vh;
  opacity: 0.3;
  position: absolute;
  left: -15rem;
  top: 8%;
  -webkit-transform: rotate(195deg);
  transform: rotate(195deg);
  width: 110vw;
  z-index: -1;
}
.sub-portfolio-detail p {
  font-size: 1.2rem;
  font-weight: 500;
}

.sub-portfolio-type .active {
  color: #ff2e29;
}

.subportfolio-page .custom-container {
  margin-block: 5rem !important;
  margin-inline: 7vw;
}

.subportfolio-page .border-left {
  width: auto;
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 40%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 90%) 1;
}
.subportfolio-page .border-left:nth-child(1) {
  top: 0%;
}
.subportfolio-page .border-left:nth-child(2) {
  top: 35%;
}
.subportfolio-header h2 {
  font-size: 5.3rem;
  font-weight: 800;
  text-align: center;
}

.sub-portfolio-type img {
  display: none;
}
@media screen and (max-width: 991px) {
  .subportfolio-page .shadow {
    display: none;
  }
  .subportfolio-page .custom-container {
    display: block;
  }
  .subportfolio-page .border-left {
    display: none;
  }
  .subportfolio-page .detail-item {
    margin-left: 0rem;
  }
  .sub-portfolio-detail p {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .sub-portfolio-type p {
    font-size: 1rem;
  }
  .subportfolio-header h2 {
    font-size: 3rem;
    font-weight: 800;
  }
  .sub-portfolio-type img {
    display: flex;
    height: 0.5rem;
    width: 0.5rem;
    margin-left: 0.5rem;
  }
}
.subportfolio-header {
  word-wrap: break-word;
}
.subportfolio-header img {
  margin-top: 10%;
  margin-left: 2%;
}
@media screen and (max-width: 768px) {
  .subportfolio-header h2 {
    font-size: 2rem;
    font-weight: 800;
  }

  .subportfolio-header img {
    margin-top: 10%;
    margin-left: 4%;
    height: 1.5rem;
  }
}
@media screen and (max-width: 468px) {
  .subportfolio-header h2 {
    font-size: 1.75rem;
    line-height: 1.7rem;
    font-weight: 800;
  }
  .subportfolio-header img {
    margin-top: 5%;
    margin-left: 4%;
    height: 1rem;
  }
}
.simplebar-track {
  background-color: #ededed !important;
  padding: 0;
  width: 5px !important;
  border-radius: 10px;
  overflow: visible o !important;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #ff2e29;
  border-radius: 10px;
  height: 5rem !important;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #ff2e29;
}
.simplebar-scrollbar::before {
  background-color: #ff2e29 !important; /* Thumb color */
  opacity: 1 !important;
  width: 5px !important;
  border-radius: 10px !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}
