/* Home Page related css */
p {
  font-family: poppins;
  font-size: 20px;
}

.home-carousel {
  text-align: center;
  padding: 50px 20px;
}

.orange-heading {
  color: #ff2e29;
  /* font-size: 42px; */
}
/* 1. scale and layout setting at 100% */
@media (resolution: 1dppx) {
  .leftshadow {
    height: 15% !important;
  }
}
/* 2. scale and layout setting at 125% */
@media (resolution: 1.25dppx) {
  #homepage .swiper-slide {
    margin-right: 10px !important;
    max-width: 290px !important;
    max-height: 310px !important;
  }
  .swiper_arrows {
    column-gap: 15rem !important;
    left: 39% !important;
  }
  .wid-75 {
    width: 75% !important;
  }
}
/* 3. scale and layout setting at 150% */
@media (resolution: 1.5dppx) {
  .arrow-top {
    top: -130px !important;
  }
  #homepage .swiper-slide {
    margin-right: 10px !important;
    max-width: 240px !important;
    max-height: 300px !important;
  }
  .swiper_arrows {
    column-gap: 12rem !important;
    top: 30% !important;
    left: 39% !important;
  }
}
/* @media (min-width: 1536px) and (max-width: 1536px) {
  .clients h1 {
    font-size: 130px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .clients h1 {
    font-size: 130px !important;
  }
} */
@media (min-width: 414px) and (max-width: 639px) {
  .mySwiper::before,
  .mySwiper::after {
    display: none;
  }
}
/* Media query for 110% zoom level */
@media (min-resolution: 110dpi) {
}

/* 4. scale and layout setting at 175% */
@media (resolution: 1.75dppx) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .swiper-slide-active {
    margin-right: 0px !important;
  }
  .swiper_arrows {
    left: 0% !important;
    column-gap: 15rem !important;
  }
  .mySwiper::before,
  .mySwiper::after {
    display: none;
  }
  #homepage .swiper-slide {
    margin-right: 0px !important;
  }
}
/* scale 125% */
@media (min-width: 1536px) {
  /* Styles for viewport widths of 1536px and above */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .swiper_arrows {
    column-gap: 15rem !important;
  }
}

@media (max-width: 640px) {
  .orange-heading {
    font-size: 18px;
  }
}
/* Styles for viewports 1745px wide or greater */
@media (min-width: 1745px) and (max-width: 1745px) {
  .swiper_arrows {
    column-gap: 18rem !important;
  }
}
@media (min-width: 1745.45px) and (max-width: 1745.45px) {
  .swiper_arrows {
    column-gap: 18rem !important;
  }
  .mySwiper::after {
    right: -80% !important;
  }
}
@media (min-width: 2133.33px) and (max-width: 2133.33px) {
  .white-shadow {
    top: 19% !important;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 414px) and (max-width: 414px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 375px) and (max-width: 375px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 412px) and (max-width: 412px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}

@media (min-width: 430px) and (max-width: 430px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 768px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -65% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30% !important;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 820px) and (max-width: 820px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -65% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100% !important;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 912px) and (max-width: 912px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -70% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 540px) and (max-width: 540px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -80% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
  .home-swiper img {
    width: 500px !important;
  }
  .clients h1 {
    font-size: 100px !important;
  }
  .mobile-client-logos {
    position: relative !important;
  }
}
@media (min-width: 853px) and (max-width: 853px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -80% !important;
    display: none;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
    display: none;
  }
  .white-shadow {
    display: none;
  }
  .home-swiper img {
    width: 500px !important;
  }
  .clients h1 {
    font-size: 100px !important;
  }
  .mobile-client-logos {
    position: relative !important;
  }
}
@media (min-width: 344px) and (max-width: 344px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100% !important;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .clients h1 {
    font-size: 135px !important;
  }
}
@media (min-width: 414px) and (max-width: 414px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}
@media (min-width: 390px) and (max-width: 390px) {
  .mobile-swiper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/right-slider-shadow-mobile.png') !important;
    z-index: 2;
    background-repeat: no-repeat;
    right: -75% !important;
  }

  .mobile-swiper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5);  */
    background-image: url('/public/images/left-slider-shadow-mobile.png') !important;
    z-index: 2;
    left: 0rem;
    background-repeat: no-repeat;
  }
  .white-shadow {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 820px) and (max-width: 820px) {
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 912px) and (max-width: 912px) {
  .clients h1 {
    font-size: 100px !important;
  }
}
@media (min-width: 540px) and (max-width: 540px) {
  .home-swiper img {
    width: 500px !important;
  }
  .clients h1 {
    font-size: 100px !important;
  }
  .mobile-client-logos {
    position: relative !important;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) {
  .clients h1 {
    font-size: 135px !important;
  }
}
/*clients css*/

.clients .clients_head {
  position: relative;
}

.clients h1 {
  /* color: #fff; */
  margin: 0;
  /* font-size: 130px; */
  white-space: nowrap;
  text-transform: uppercase;
  /* text-wrap: nowrap; */
  text-align: center;
  /* line-height: 1; */
  /* text-shadow: 0 0 10px #FF2E29; */
  /* font-weight: 800; */
  /* opacity: 35%; */
}

.mobile-client {
  color: #fff;
  /* margin: 0; */
  font-size: 55px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 10px #fff;
  font-weight: 800;
  opacity: 30%;
  white-space: break-spaces;
  line-height: 70px;
}

.text-orange {
  color: #ff2e29;
}

@media screen and (min-width: 991px) {
  section .verticalLine {
    display: block;
  }
}

section .verticalLine {
  position: absolute;
  width: auto;
  left: 16.2%;
  /*top: 10%;*/
  /* z-index: 11; */
  height: 25rem;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #0c0c0c 0%, #262626 30%) 1;
  /*display: none;*/
}

.verticalLine {
  z-index: -1;
}

section .verticalLine.right {
  left: unset;
  right: 16.2%;
  height: 25rem;
}

/*slider arrows*/
.arrow-top {
  top: -13rem;
}

@media (min-width: 1024px) {
  .arrow-top {
    top: -13rem;
  }
}

.letter-5 {
  letter-spacing: 3px;
}

.viewallprojects {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 2rem;  */
  width: 100%;
}

.viewallprojects a {
  font-size: 15px;
  color: #ff2e29;
  text-transform: uppercase;
  line-height: 1.1;
  text-decoration: none;
  border-bottom: 1px solid #ff2e29;
  display: flex;
  align-items: center;
}

.viewallprojects svg {
  fill: #ff2e29;
  vertical-align: middle;
}

.verticalLine-left {
  height: 70rem;
  /* top: 10%; */
  bottom: -23%;
  position: absolute;
  left: -10%;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 30%, #0c0c0c 50%, #262626 30%) 1;
}

.verticalLine-right {
  position: absolute;
  width: auto;
  right: -10%;
  top: 75% !important;
  /* z-index: 11; */
  height: 42.5rem;
  border-left: 1px solid;
  background: transparent;
  border-image: linear-gradient(0deg, #262626 100%, #0c0c0c 30%) 1;
}

.leftshadow {
  background-color: #ffffffa8;
  border-radius: 50%;
  display: block;
  filter: blur(80px);
  height: 30%;
  opacity: 0.25;
  position: absolute;
  right: 70%;
  top: 30%;
  transform: rotate(300deg);
  width: 20%;
  z-index: -1;
}

.bg-orange {
  background-color: #ff2e29;
}

/* pagination home slider */

.crousal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top:5rem; */
  /*   background-color:red; */
  position: relative;
}

.crousal-left-count {
  color: white;
  margin-right: 1rem;
}

.crousal-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.crousal-right-count {
  color: white;
  margin-left: 1rem;
}

.crousal-center-button {
  border: 1px solid white;
  border-radius: 15%;
  width: 2rem;
  height: 1.6rem;
  background-color: #0c0c0c;
  display: inline-block;
  z-index: 11;
  margin: 0 0.3rem;
}

.crousal-pin {
  width: 0.06rem;
  background-color: white;
  height: 1.2rem;
  margin: 0 0.4rem;
  display: inline-block;
}

.trial-pin-1 {
  width: 0.06rem;
  background-color: white;
  height: 1.2rem;
  display: inline-block;

  margin-right: -0.7rem;
  margin-left: 0.5rem;
}

.trial-pin-2 {
  width: 0.06rem;
  background-color: white;
  height: 1.2rem;
  display: inline-block;
  margin-left: -0.9rem;
  margin-right: 0.7rem;
  height: 1.8rem;
}

.trial-pin-3 {
  width: 0.06rem;
  background-color: white;
  height: 1.2rem;
  display: inline-block;
  margin-left: 0.6rem;
  margin-right: -0.9rem;
  height: 1.8rem;
}

.crousal-pin:nth-child(1),
.crousal-pin:nth-child(11) {
  height: 1.8rem;
  opacity: 10%;
}

/* .crousal-pin:nth-child(2), */
.crousal-pin:nth-child(10) {
  height: 1.3rem;
  opacity: 30%;
}

/* .crousal-pin:nth-child(3), */
.crousal-pin:nth-child(7) {
  height: 1rem;
}

/* .crousal-pin:nth-child(4), */
.crousal-pin:nth-child(6) {
  height: 1.3rem;
}

.pin-x {
  height: 1.5rem;
  opacity: 15%;
}

.pin-x0 {
  height: 1.3rem;
  opacity: 30%;
}

.pin-x1 {
  height: 1rem;
}

.pin-y {
  height: 1rem;
}

.IndicatorsPosition {
  margin: 40px 0px;
}

/* slider left and right shadow */
.mySwiper {
  position: relative;
  z-index: 1;
  /* Ensure content is above pseudo-elements */
}

@media (min-width: 1240px) and (max-width: 1280px) {
  .mySwiper::after {
    right: -76% !important;
  }
}
@media screen and (max-device-width: 639px) and (min-device-width: 458px) {
  .mobile-wid {
    width: 40% !important;
  }
  .mobile-top {
    top: -17% !important;
  }
}
/* client logos left and right shadow */

.shadows .leftShadow {
  height: 9rem;
  position: absolute;
  z-index: 2;
  left: 0rem;
  width: 10rem;
}

.shadows .rightShadow {
  height: 9rem;
  position: absolute;
  z-index: 2;
  right: 0rem;
  width: 10rem;
}
.mobile-clients-logos {
  position: absolute;
  /* top: -10%; */
}
.mobile-clients-logos .shadows .leftShadow {
  width: auto;
}

.swiper_arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 19rem;
  margin-top: 1rem;
  position: absolute;
  top: 40%;
  left: 39%;
}
.swiper_arrows button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 2.8rem;
  height: 2.8rem; */
  border-radius: 50%;
  border: 1px solid #fefbf7;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
  transition: background-color 0.1s ease-out;
}
.swiper_arrows button .arrowcircle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper_arrows button .bg_fill:first-child {
  left: 1rem;
}

.swiper_arrows button .bg_fill {
  display: block;
  width: 100%;
  position: absolute;
  height: 120%;
  bottom: -10rem;
  transition: 0.2s all ease-in-out;
}
.swiper_arrows button svg {
  position: relative;
}

.width-fill {
  width: -webkit-fill-available !important;
}
.w-5rem {
  width: 5rem !important;
}

.swiper-slide-active {
  cursor: pointer !important;
}
.shadows-slider .leftShadow-slider {
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0rem;
  width: 20%;
}
.shadows-slider .rightShadow-slider {
  height: 100%;
  position: absolute;
  z-index: 2;
  right: 0rem;
  width: 20%;
}
#homepage .swiper-slide {
  opacity: 50%;
}
.swiper-slide-active {
  opacity: 100% !important;
}
.job-head {
  white-space: pre-wrap;
  word-break: break-word;
}
.team-border {
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #0c0c0c, #424242 80%, #0c0c0c) 1;
  width: 100%;
}

@-moz-document url-prefix() {
  @media screen and (max-device-width: 508px) and (min-device-width: 508px) {
    .mobile-top {
      top: -5% !important;
    }
    .mobile-wid {
      width: 50% !important;
    }
  }
}

.ja-ml {
  margin-left: -20%;
  /* letter-spacing: -15px; */
}

@media (max-width: 767px) {
  .ja-ml {
    margin-left: -28%;
    letter-spacing: -6px;
  }
}

@media (max-width: 320px) {
  .ja-ml {
    margin-left: -15%;
    /* letter-spacing: -5px; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ja-ml {
    margin-left: -36%;
    /* letter-spacing: -24px; */
  }
}
