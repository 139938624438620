.line-height-30 {
  line-height: 30px;
}
.line-height-60 {
  line-height: 60px;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-58 {
  font-size: 58px;
}

.map-gradient {
  background-image: 'linear-gradient(#0c0c0ca6 40%, #000000b8 50%)';
}
.background-arrow-left {
  background-image: url(/public/images/arrow-left-p.png);
}
.background-arrow-right {
  background-image: url(/public/images/arrow-right-p.png);
}
