/* This File is related to Client and Partners Slider */

@media screen and (max-width: 991px) {
  .clientsslider {
    top: 0;
    width: 100%;
    margin: 5rem 0rem;
  }

  .clientsslider .owl-item img {
    width: 80% !important;
    margin: 0 auto;
  }
}

.clientsslider .owl-stage {
  transition-timing-function: linear !important;
}
.clientsslider .swiper-wrapper {
  align-items: center;
}

.clientsslider .owl-stage {
  display: flex;
  align-items: center;
}

.clientsslider .owl-item {
  align-items: center;
  justify-content: center;
}

.clientsslider::before {
  content: '';
  background-size: 100% 100%;
  width: 8%;
  height: 7rem;
  position: absolute;
  top: -1rem;
  left: -2rem;
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .clientsslider {
    margin: 2rem 0rem;
  }
}
/* 1. scale and layout setting at 100% */
@media (resolution: 1dppx) {
}
/* 2. scale and layout setting at 125% */
@media (resolution: 1.25dppx) {
}
/* 3. scale and layout setting at 150% */
@media (resolution: 1.5dppx) {
}

/* 4. scale and layout setting at 175% */
@media (resolution: 1.75dppx) {
}
