/* Navigation Bar related css */

a.active {
  color: #ff2e29;
}

nav .lang_dropdown {
  padding: 0.5rem;
  position: absolute;
  background: #0c0c0c;
  border: 1px solid #fff;
  top: 3.8rem;
  right: 2rem;
  min-width: 18rem;
  z-index: 999;
}
.mobile-lang p {
  color: #fff;
  padding: 0.4rem 1rem;
  cursor: pointer;
}
.mobile-lang p {
  font-size: 16px !important;
}
.mobile-lang p.active {
  color: #ff2e29;
}
nav .lang_dropdown p {
  color: #fff;
  padding: 0.4rem 1rem;
  cursor: pointer;
}

nav .lang_dropdown p.active {
  color: #ff2e29;
}

.lang_dropdown p:hover {
  color: #ff2e29;
}

/* mobile sub menu */

.submenu-top div {
  font-weight: 800;
  font-family: 'Montserrat';
  text-transform: uppercase;
  padding-bottom: 2px;
  font-size: 34px;
}
.mobile-menu-h {
  height: calc(100dvh - 3.1rem);
  height: -webkit-calc(100dvh - 3.1rem);
}
