/* Contact Page Css here  */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}
.contact-bg {
  background: url('../../../public/images/Contact-us-image.png');
  background-size: 100% 100% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.contact-bg-mobile {
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  background-size: contain !important;
}
.radial-gradient-bg {
  background: radial-gradient(circle, #c7dddb13, #000000);
}
.select-option option {
  background-color: #0c0c0c !important;
}
.accent-primary-500 {
  accent-color: #ff2e29;
}
[type='search'] {
  background-color: transparent !important;
}
[type='search']:focus {
  border: none !important;
  outline: none !important;
}
.dropdown-item {
  padding: 0.4rem 1rem;
  font-size: 1rem;
  letter-spacing: 0.1rem;
}
.dropdown-item:hover {
  color: #ff2e29;
}

.scrollable::-webkit-scrollbar {
  width: 12px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #888;
}
.border-gradient {
  border: 1px solid;
  border-image-source: radial-gradient(
    142.36% 126.71% at 74.86% -1.69%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.contact-checkbox label input:after {
  border: solid var(--primary-color);
  border-width: 0 1px 1px 0;
  content: '';
  display: block;
  height: 0.7rem;
  left: 0.4rem;
  position: absolute;
  top: 0.55rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 0.3rem;
}
.bg-contain {
  background-size: contain !important;
}

textarea {
  resize: none;
}
@-moz-document url-prefix() {
  .left44 {
    left: 44px;
  }
}
.submit-btn:hover ~ .submit-span {
  background-color: red;
}
