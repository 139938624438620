.pagination {
  margin-top: 3%;
  margin-left: inherit;
  margin-right: inherit;
}
.pagination .pagination-left-button:disabled,
.pagination .pagination-left-button.disabled,
.pagination .pagination-right-button:disabled,
.pagination .pagination-right-button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.pagination .pagination-left-button,
.pagination .pagination-right-button,
.pagination-left-button,
.pagination-right-button {
  padding: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #fff;
  background: none;
  overflow: hidden;
  transition: all 250ms;
  cursor: pointer;
}
@media screen and (min-width: 991px) {
  .pagination .pagination-left-button:hover,
  .pagination .pagination-right-button:hover,
  .pagination-left-button:hover,
  .pagination-right-button:hover {
    box-shadow: inset 0 -5.25em 0 0 #ff2e29;
  }
}
.pagination .pages-link {
  opacity: 0.5;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  text-decoration: none;
  color: #ffffff;
}
.pagination .pages-link.active {
  opacity: 1;
}
